<template>
  <div class="navigation">
    <div class="nav-mob" :class="showMenu ? 'active' : ''">
      <div class="nav-trigger">
        <label for="check">
          <input type="checkbox" id="check" @click="toggleNav" />
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
      <nav class="nav" :class="showMenu ? 'show' : 'hidden'">
        <ul>
          <li class="nav__item">
            <router-link
              class="nav__link"
              :class="{
                'router-link-active':
                  this.$route.name === 'CitizenCharterDetail',
              }"
              to="/citizen-charter"
              @click="hideNav"
              >नागरिक वडापत्र</router-link
            >
          </li>
          <li class="nav__item">
            <router-link
              :class="{
                nav__link: true,
                'router-link-active': this.$route.name === 'Poi',
              }"
              to="/e-profile"
              @click="hideNav"
              >वस्तुगत विवरण</router-link
            >
          </li>
          <li class="nav__item">
            <router-link class="nav__link" to="/grievance" @click="hideNav"
              >गुनासो/प्रतिक्रिया</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'khadachakra'">
            <router-link class="nav__link" to="/sectors/health" @click="hideNav"
              >स्वास्थ्य सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'bheri'">
            <router-link
              class="nav__link"
              to="/sectors/education"
              @click="hideNav"
              >शिक्षा सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'kapurkot'">
            <router-link
              class="nav__link"
              to="/sectors/agriculture"
              @click="hideNav"
              >कृषि सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'triveni'">
            <router-link
              class="nav__link"
              to="/sectors/water_sanitation"
              @click="hideNav"
              >पानी र सरसफाइ सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'siddhakumakh'">
            <router-link
              class="nav__link"
              to="/sectors/water_sanitation"
              @click="hideNav"
              >पानी र सरसफाइ सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'shibalaya'">
            <router-link
              class="nav__link"
              to="/sectors/agriculture"
              @click="hideNav"
              >कृषि सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item">
            <router-link
              :class="{
                nav__link: true,
                'router-link-active':
                  this.$route.name === 'DocumentLibraryDetail',
              }"
              to="/document-library"
              @click="hideNav"
              >कागजात संग्रह</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
    <div class="nav-web">
      <nav class="nav">
        <ul class="flex">
          <li class="nav__item">
            <router-link
              class="nav__link"
              :class="{
                'router-link-active':
                  this.$route.name === 'CitizenCharterDetail',
              }"
              to="/citizen-charter"
              >नागरिक वडापत्र</router-link
            >
          </li>
          <li class="nav__item">
            <router-link
              :class="{
                nav__link: true,
                'router-link-active': this.$route.name === 'Poi',
              }"
              to="/e-profile"
              >वस्तुगत विवरण</router-link
            >
          </li>
          <li class="nav__item">
            <router-link class="nav__link" to="/grievance"
              >गुनासो/प्रतिक्रिया</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'khadachakra'">
            <router-link class="nav__link" to="/sectors/health"
              >स्वास्थ्य सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'bheri'">
            <router-link class="nav__link" to="/sectors/education"
              >शिक्षा सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'kapurkot'">
            <router-link class="nav__link" to="/sectors/agriculture"
              >कृषि सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'triveni'">
            <router-link class="nav__link" to="/sectors/water_sanitation"
              >पानी र सरसफाइ सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'siddhakumakh'">
            <router-link class="nav__link" to="/sectors/water_sanitation"
              >पानी र सरसफाइ सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item" v-if="localGov == 'shibalaya'">
            <router-link class="nav__link" to="/sectors/agriculture"
              >कृषि सम्बन्धित</router-link
            >
          </li>
          <li class="nav__item">
            <router-link
              :class="{
                nav__link: true,
                'router-link-active':
                  this.$route.name === 'DocumentLibraryDetail',
              }"
              to="/document-library"
              >कागजात संग्रह</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppNavbar",
  data() {
    return {
      localGov: process.env.VUE_APP_LOCAL_GOV,
      showMenu: false,
    };
  },
  methods: {
    toggleNav: function () {
      this.showMenu = !this.showMenu;
    },
    hideNav: function () {
      this.showMenu = false;
      document.querySelector(".nav-trigger input").checked = false;
    },
  },
  watch: {
    showMenu: function () {
      document.body.style.position = this.showMenu ? "fixed" : "";
    },
  },
  // mounted() {
  //   var navLink = document.querySelector(".nav__link");
  //   var nav = document.querySelector("nav");
  //   var navMob = document.querySelector(".nav-mob");
  //   navLink.addEventListener('click', function(event) {
  //       nav.classList.add('hidden');
  //       nav.classList.remove('show');
  //       navMob.classList.remove('active');
  //   });
  // }
};
</script>
<style lang="scss" scoped>
.nav {
  &__item:not(:last-child) {
    margin-right: 32px;
  }
  &__link {
    border-bottom: 2px solid transparent;
    color: $neutrals-black;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $primary;
    }
  }
}

.router-link {
  &-active,
  &-active:hover {
    border-color: $primary;
    color: $primary;
  }
}

.nav-mob {
  > nav {
    background: #b95d9a;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
  label {
    display: flex;
    flex-direction: column;
    width: 32px;
    cursor: pointer;
  }
  label span {
    background: #b95d9a;
    border-radius: 10px;
    height: 4px;
    margin: 3px 0;
    position: relative;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    z-index: 10;
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 75%;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(5px, 0px);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(14px, -5px) rotatez(45deg);
  }
  .nav__item {
    font-size: 24px;
    margin: 50px 0;
    text-align: center;
    a {
      color: $neutrals-white;
    }
  }
  ul {
    margin: 20%;
  }
  &.active {
    label span {
      background: $neutrals-white;
    }
    .nav-trigger {
      position: fixed;
      right: 20px;
      top: 20px;
      z-index: 101;
    }
  }
}

.hidden {
  display: none;
}

.nav-mob {
  display: none;
  @media all and (max-width: 999px) {
    display: block;
  }
}

.nav-web {
  @media all and (max-width: 999px) {
    display: none;
  }
}
</style>
